var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag" },
    [
      _c(
        "el-row",
        {
          staticClass: "row-bg",
          staticStyle: { height: "40px" },
          attrs: { type: "flex", justify: "space-between", align: "middle" }
        },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-checkbox",
                {
                  class: Object.values(_vm.tagList).length
                    ? ""
                    : _vm.editChecked
                    ? ""
                    : "opacity",
                  attrs: {
                    disabled: Object.values(_vm.tagList).length
                      ? false
                      : _vm.editChecked
                      ? false
                      : true
                  },
                  on: {
                    change: function($event) {
                      return _vm.noGroupTag()
                    }
                  },
                  model: {
                    value: _vm.editChecked,
                    callback: function($$v) {
                      _vm.editChecked = $$v
                    },
                    expression: "editChecked"
                  }
                },
                [_vm._v("仅显示未分组标签")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              !_vm.editVisible
                ? _c(
                    "el-button",
                    { staticClass: "add_tag", on: { click: _vm.onAddTag } },
                    [_vm._v("添加")]
                  )
                : _c(
                    "div",
                    { staticClass: "outer_edit" },
                    [
                      _c("transition", { attrs: { name: "el-fade-in" } }, [
                        _vm.ExistedTag
                          ? _c(
                              "div",
                              { staticClass: "existed_tag category_tag" },
                              [_vm._v(_vm._s(_vm.errorWarn))]
                            )
                          : _vm._e()
                      ]),
                      _c("transition", { attrs: { name: "el-fade-in" } }, [
                        _vm.addTagSucc
                          ? _c(
                              "div",
                              { staticClass: "success_tag category_tag" },
                              [_vm._v("标签添加成功！")]
                            )
                          : _vm._e()
                      ]),
                      _c("el-input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        ref: "saveTagInput",
                        staticClass: "input-new-tag",
                        attrs: {
                          size: "small",
                          placeholder: "添加标签",
                          maxlength: 20
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.onConfirm($event)
                          }
                        },
                        model: {
                          value: _vm.addValue,
                          callback: function($$v) {
                            _vm.addValue = $$v
                          },
                          expression: "addValue"
                        }
                      }),
                      _c("div", { staticClass: "operation" }, [
                        _c("i", {
                          staticClass: "iconfont icon-duihao",
                          on: { click: _vm.onConfirm }
                        }),
                        _c("i", {
                          staticClass: "iconfont icon-cuo",
                          on: { click: _vm.onCancel }
                        })
                      ])
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { ref: "tagContent", staticClass: "tag_content" },
        [
          _vm._l(_vm.tagList, function(item, i) {
            return _c(
              "div",
              { key: i, staticClass: "tag_list" },
              [
                _c("div", { staticClass: "category_letter" }, [
                  _vm._v(" " + _vm._s(i) + " ")
                ]),
                _vm._l(item, function(list, j) {
                  return _c("div", { key: j, staticClass: "tag_outer" }, [
                    _c("div", {
                      staticStyle: { position: "absolute", top: "-40px" },
                      attrs: { id: "tag" + list.id }
                    }),
                    !list.tagNameReset
                      ? _c(
                          "div",
                          {
                            staticClass: "tag_title",
                            attrs: { id: list.id },
                            on: {
                              click: function($event) {
                                return _vm.tagClick(list.id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(list.tag_name) +
                                "（" +
                                _vm._s(list.count) +
                                "） "
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: list.tagPop,
                                    expression: "list.tagPop"
                                  }
                                ],
                                staticClass: "pop_list"
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    class: [
                                      "oper_item",
                                      "modify_group",
                                      _vm.tagGroupList.length
                                        ? ""
                                        : ["opacity", "cursor_sty"]
                                    ]
                                  },
                                  [
                                    _vm._v("修改标签组"),
                                    _vm.tagGroupList.length
                                      ? _c("i", {
                                          staticClass:
                                            "iconfont icon-sanjiaoshang"
                                        })
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "binding" },
                                      [
                                        _vm._l(_vm.tagGroupList, function(
                                          grouplist,
                                          k
                                        ) {
                                          return [
                                            _c(
                                              "p",
                                              {
                                                key: k,
                                                class: [
                                                  "tag_group",
                                                  grouplist.changeColor
                                                    ? "click_group_item"
                                                    : ""
                                                ],
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    if (
                                                      $event.target !==
                                                      $event.currentTarget
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.checkTagGroup(
                                                      $event,
                                                      grouplist,
                                                      list.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-check"
                                                }),
                                                _vm._v(
                                                  _vm._s(
                                                    grouplist.tag_group_name
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "oper_item",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.resetTagName(list)
                                      }
                                    }
                                  },
                                  [_vm._v("重命名")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "oper_item",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteGroup(list.id)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            ),
                            _c(
                              "transition",
                              { attrs: { name: "el-fade-in" } },
                              [
                                list.tagExistedTag
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "existed_tag category_tag",
                                        staticStyle: {
                                          left: "0",
                                          right: "auto",
                                          top: "-31px"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.editErrorWarn))]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "transition",
                              { attrs: { name: "el-fade-in" } },
                              [
                                list.resetTagSucc
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "success_tag category_tag",
                                        staticStyle: {
                                          left: "0",
                                          right: "auto"
                                        }
                                      },
                                      [_vm._v("标签重命名成功！")]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              "text-align": "right",
                              "line-height": "32px",
                              "margin-right": "15px"
                            }
                          },
                          [
                            _c("el-input", {
                              directives: [
                                { name: "focus", rawName: "v-focus" }
                              ],
                              ref: "saveTagInput",
                              refInFor: true,
                              staticClass: "input-new-tag",
                              staticStyle: {
                                "line-height": "32px",
                                "margin-bottom": "0",
                                width: "140px",
                                "margin-left": "0"
                              },
                              attrs: { size: "small", maxlength: 20 },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  return _vm.onFocus($event)
                                },
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  return _vm.onFocus($event)
                                },
                                keyenter: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  return _vm.onFocus($event)
                                }
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.onFocus($event)
                                },
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.onConfirmTag(list)
                                }
                              },
                              model: {
                                value: list.editValue,
                                callback: function($$v) {
                                  _vm.$set(list, "editValue", $$v)
                                },
                                expression: "list.editValue"
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "operation",
                                staticStyle: {
                                  "line-height": "32px",
                                  "vertical-align": "middle",
                                  "margin-bottom": "0"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-duihao",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.onConfirmTag(list)
                                    }
                                  }
                                }),
                                _c("i", {
                                  staticClass: "iconfont icon-cuo",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.onCancelTag(list)
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                  ])
                })
              ],
              2
            )
          }),
          _vm.ifNodata
            ? _c("noResult", {
                attrs: {
                  iconUrl: "icon-meiyousucai",
                  iconSty: "font-size:80px",
                  textSty: "font-size:18px",
                  noResultText: "暂无数据"
                }
              })
            : _vm._e()
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提 示",
            visible: _vm.warningDialog,
            width: "400px",
            top: "30vh",
            "custom-class": "delete-dialog",
            "show-close": true,
            "before-close": _vm.warningClose
          },
          on: {
            "update:visible": function($event) {
              _vm.warningDialog = $event
            }
          }
        },
        [
          _c("div", { staticClass: "warning_content" }, [
            _c("div", [_vm._v(" 您确定要删除该标签吗？ ")])
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.deleteTagConfirm }
                },
                [_vm._v("是")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.warningDialog = false
                    }
                  }
                },
                [_vm._v("否")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }